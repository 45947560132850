import apiClient from 'services/apiClient'
import {
  UpdateDataRequestQuestionV2In,
  InsertDataRequestQuestionV2In,
  CreateDataRequestTemplateV3In,
  UpdateDataRequestTemplateV3In,
  DataRequestFilterV2ApiDto,
  DataRequestAnswerReviewState,
  UpdateDataRequestDueDateIn,
  CreateEmailTemplateIn,
  UpdateEmailTemplateIn,
  EmailTemplateType,
} from 'typescript-axios'
import { FilterCriteriaProps, formatFiltersForApi } from 'views/buyer/Scorecards/helpers'

export const getDataRequests = async ({
  ids,
  supplierFilter,
  dataRequestTemplateId,
  dueDateFrom,
  dataRequestQuestionIdsIn,
  dueDateTo,
  state,
  page,
  pageSize,
}: {
  page?: number
  pageSize?: number
} & DataRequestFilterV2ApiDto) => {
  const { data } = await apiClient.postV3DataRequests(
    {
      ids,
      supplierFilter,
      dataRequestTemplateId,
      dataRequestQuestionIdsIn,
      dueDateFrom,
      dueDateTo,
      state,
    },
    page,
    pageSize
  )
  return data
}

export const getDataRequest = async ({ id }: { id: string }) => {
  const { data } = await apiClient.getV3DataRequestsId(id)
  return data
}

export const sendDataRequest = async ({
  filters,
  dueDate,
  dataRequestTemplateId,
}: {
  filters: FilterCriteriaProps[]
  dueDate: string
  dataRequestTemplateId: string
}) => {
  return apiClient.postV3DataRequestsActionsSend({
    supplierFilter: formatFiltersForApi(filters),
    dueDate,
    dataRequestTemplateId,
  })
}

export const getDataRequestQuestions = async ({
  ids,
  dataSourceId,
  policyParameterId,
  dataRequestId,
  title,
  page,
  pageSize,
}: {
  ids?: string[]
  dataSourceId?: string
  policyParameterId?: string
  dataRequestId?: string[]
  title?: string
  page: number
  pageSize: number
}) => {
  const { data } = await apiClient.getV2DataRequestsQuestions(
    ids,
    dataSourceId,
    policyParameterId,
    dataRequestId,
    title,
    page,
    pageSize
  )
  return data
}

export const updateDataRequestQuestion = async ({
  id,
  title,
  titleTranslations,
  description,
  mapping,
  answerType,
}: {
  id: string
} & UpdateDataRequestQuestionV2In) => {
  const { data } = await apiClient.putV2DataRequestsQuestionsId(id, {
    title,
    titleTranslations,
    description,
    mapping,
    answerType,
  })
  return data
}

export const createDataRequestQuestion = async ({
  title,
  titleTranslations,
  description,
  answerType,
  mapping,
}: InsertDataRequestQuestionV2In) => {
  const { data } = await apiClient.postV2DataRequestsQuestions({
    title,
    titleTranslations,
    description,
    answerType,
    mapping,
  })
  return data
}

export const getEmailTemplates = async ({
  type,
  page,
  pageSize,
}: {
  type?: EmailTemplateType
  page: number
  pageSize: number
}) => {
  const { data } = await apiClient.getV1EmailTemplates(type, page, pageSize)
  return data
}

export const saveEmailTemplate = async ({
  name,
  emailTemplateType,
  description,
  subject,
  subjectTranslations,
  messagingTemplate,
  messagingTemplateTranslations,
  replyTo,
}: CreateEmailTemplateIn) => {
  const { data } = await apiClient.postV1EmailTemplates({
    name,
    emailTemplateType,
    description,
    subject,
    subjectTranslations,
    messagingTemplate,
    messagingTemplateTranslations,
    replyTo,
  })
  return data
}

export const updateEmailTemplate = async ({
  id,
  name,
  description,
  subject,
  subjectTranslations,
  messagingTemplate,
  messagingTemplateTranslations,
  replyTo,
}: UpdateEmailTemplateIn & { id: string }) => {
  const { data } = await apiClient.patchV1EmailTemplatesId(id, {
    name,
    description,
    subject,
    subjectTranslations,
    messagingTemplate,
    messagingTemplateTranslations,
    replyTo,
  })
  return data
}

export const getDataRequestTemplates = async ({
  name,
  ids,
  questionIds,
  page,
  pageSize,
}: {
  name?: string
  ids?: string[]
  questionIds?: string[]
  page: number
  pageSize: number
}) => {
  const { data } = await apiClient.getV3DataRequestTemplates(name, ids, questionIds, page, pageSize)
  return data
}

export const createDataRequestTemplate = async ({
  name,
  description,
  blocks,
  sendEmailTemplateId,
  questionIds,
}: CreateDataRequestTemplateV3In) => {
  const { data } = await apiClient.postV3DataRequestTemplates({
    name,
    description,
    blocks,
    sendEmailTemplateId,
    questionIds,
  })
  return data
}

export const updateDataRequestTemplate = async ({
  id,
  name,
  description,
  blocks,
  sendEmailTemplateId,
  questionIds,
}: { id: string } & UpdateDataRequestTemplateV3In) => {
  const { data } = await apiClient.putV3DataRequestTemplates(id, {
    name,
    description,
    blocks,
    sendEmailTemplateId,
    questionIds,
  })
  return data
}

export const getDataRequestAnswers = async ({
  supplierId,
  dataRequestId,
  dataRequestQuestionId,
  reviewState,
  latest,
  page,
  pageSize,
}: {
  page: number
  pageSize: number
  supplierId?: string[]
  dataRequestId?: string[]
  dataRequestQuestionId?: string[]
  reviewState?: DataRequestAnswerReviewState[]
  latest?: boolean
}) => {
  const { data } = await apiClient.getV3DataRequestAnswers(
    supplierId,
    dataRequestId,
    dataRequestQuestionId,
    reviewState,
    latest,
    page,
    pageSize
  )
  return data
}

export const getDataRequestAnswersDataEntries = async ({
  dataRequestId,
}: {
  dataRequestId: string
}) => {
  const { data } = await apiClient.getV4DataRequestsIdDataEntries(dataRequestId)
  return data
}

export const getDataRequestAnswersFiles = async ({ answerId }: { answerId: string }) => {
  const { data } = await apiClient.getV3DataRequestAnswersIdFiles(answerId)
  return data
}

export const getAllDataRequestFiles = async ({ dataRequestId }: { dataRequestId: string }) => {
  const { data } = await apiClient.getV3DataRequestsIdFiles(dataRequestId)
  return data
}

export const updateDataRequestDueDate = async ({
  dataRequestIds,
  dueDate,
}: UpdateDataRequestDueDateIn) => {
  const { data } = await apiClient.postV3DataRequestsActionsUpdateDueDate({
    dataRequestIds,
    dueDate,
  })

  return data
}

export const getDataRequestQuestionEditValidation = async ({
  questionId,
}: {
  questionId: string
}) => {
  const { data } = await apiClient.getV2DataRequestsQuestionsIdEditValidation(questionId)
  return data
}

export const generateInvitationLink = async ({ dataRequestId }: { dataRequestId: string }) => {
  const { data } = await apiClient.postV3DataRequestsIdGenerateLink(dataRequestId)
  return data
}
